//When we disable the search criteria, it should also reset these search criteria value.
//This file is to keep mapping between the search criteria checkbox value and related field
//when disable 'coVslVoy', we need to clear field 'co','vsl','voy'

export const ProposalEnquiryCriteriaCheckboxRelatedField: { [key: string]: string[] } = {
    companyCode: ['companyCode'],
    proposalNo: ['proposalNo'],
    operatingTml: ['operatingTml'],
    effectiveDate: ['effectiveFromDate', 'effectiveToDate'],
    tariffType: ['tariffType'],
    tariffCode: ['tariffCode'],
    proposalStatus: ['proposalStatus'],
    activeInd: ['activeInd'],
    confirmStatusList: ['confirmStatusList'],
}