import { useProposalEnquiryVM } from "presentation/hook/ProposalEnquiry/useProposalEnquiryVM";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useProposalEnquiryTracked } from "presentation/store/ProposalEnquiry/ProposalEnquiryProvider";
import { ProposalEnquiryDetailContainer } from "presentation/view/container/ProposalEnquiry/ProposalEnquiryDetailContainer";
import { useEffect, useMemo } from "react";
import { Loader, ResizablePanel } from "veronica-ui-component/dist/component/core";
import { DeletePreferenceModal } from "./ConfirmModal/DeletePreferenceModal";
import { SaveNewPreferenceModal } from "./ConfirmModal/SaveNewPreferenceModal";
import { ProposalEnquiryRightPanel } from "./ProposalEnquiryPanel/ProposalEnquiryRightPanel";
import { ProposalEnquiryTablePanel } from "./ProposalTablePanel/ProposalEnquiryTablePanel";

export function isSearchCriteriaSet(obj1: any, obj2: any) { return JSON.stringify(obj1) === JSON.stringify(obj2); }

export const ProposalEnquiryComp: React.FC = () => {
    const [proposalEnquiryState] = useProposalEnquiryTracked();
    const searchCounter = proposalEnquiryState.searchCounter;
    const isShowProposalDetail = proposalEnquiryState.isShowProposalDetail;
    const isEditSearchCriteria = proposalEnquiryState.isEditSearchCriteria;
    const isShowRightCriteriaPanel = proposalEnquiryState.isShowRightCriteriaPanel;
    const [anaInfoState] = useANAInfoTracked();

    const proposalEnquiryVM = useProposalEnquiryVM();

    useEffect(() => {
        if (anaInfoState.userName) {
            proposalEnquiryVM.setUserEmail('im-proposal-search-' + anaInfoState.userName)
        }
    }, [proposalEnquiryVM, anaInfoState.userName])

    useEffect(() => {
        proposalEnquiryVM.onShowLoading();

        const initialScreen = async () => {
            try {
                <h1>proposalEnquiryVM.onShowLoading()</h1>
                const results = await Promise.allSettled([
                    proposalEnquiryVM.loadAllSearchPreference('im-proposal-search-' + anaInfoState.userName),
                    proposalEnquiryVM.loadDropdownOption(),
                ])
                results.forEach((result, index) => {
                    if (index === 1 && result.status === 'fulfilled') {

                    }
                })
            } catch (error) {
            }
        }
        initialScreen().then((data) => {
            proposalEnquiryVM.onHideLoading();
        }).catch(error => {
            proposalEnquiryVM.onHideLoading();
        });
    }, [anaInfoState.userName, proposalEnquiryVM]);

    const memoProposalEnquiryTablePanel = useMemo(() => <ProposalEnquiryTablePanel />, []);
    const memoProposalEnquiryRightPanel = useMemo(() => <ProposalEnquiryRightPanel />, []);

    const memoResizablePanel = useMemo(() =>
        <ResizablePanel
            id={searchCounter === 0 ? 'im-resizable-panel-collapsed' : `im-resizable-panel${!isEditSearchCriteria ? '-active' : ''}`}
            draggable={false}
            leftChildren={memoProposalEnquiryTablePanel}
            rightChildren={memoProposalEnquiryRightPanel}
            rightSectionWidth={searchCounter === 0 ? '100%' : '45%'}
            leftSectionWidth={searchCounter === 0 ? '0%' : '100%'}
            containerWidth={'100%'} />
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [searchCounter, memoProposalEnquiryTablePanel, memoProposalEnquiryRightPanel, isEditSearchCriteria])

    // Charge Data search event listner
    // const onProposalEnquiryChange = (e: any) => {
    //     switch (e.detail.action) {
    //         case E_Custom_Dispatch_Event.PROPOSAL_ENQUIRY_EDIT_CRITERIA:
    //             proposalEnquiryVM.onEditSearchCriteria();
    //             break;
    //         case E_Custom_Dispatch_Event.PROPOSAL_ENQUIRY_OPEN_DIRECTORY:
    //             proposalEnquiryVM.onSwitchSearchCriteriaMode();
    //             break;
    //         case E_Custom_Dispatch_Event.PROPOSAL_ENQUIRY_MODAL_BACK_TO_LIST:
    //             proposalEnquiryVM.closeProposalDetail();
    //             break;
    //         case E_Custom_Dispatch_Event.PROPOSAL_ENQUIRY_PREFERENCE_SAVE_SEARCH:
    //             proposalEnquiryVM.saveSearchPreference(e.detail.data.currentSearchPreference);
    //             break;
    //         case E_Custom_Dispatch_Event.PROPOSAL_ENQUIRY_PREFERENCE_SAVE_NEW:
    //             proposalEnquiryVM.openSaveNewConfirmModal();
    //             break;
    //         case E_Custom_Dispatch_Event.PROPOSAL_ENQUIRY_PREFERENCE_DELETE:
    //             proposalEnquiryVM.openDeleteConfirmModal();
    //             break;
    //         case E_Custom_Dispatch_Event.PROPOSAL_ENQUIRY_PREFERENCE_OPEN:
    //             proposalEnquiryVM.loadSearchPreference(e.detail.data.searchPreference);
    //             break;
    //         // case E_Custom_Dispatch_Event.PROPOSAL_ENQUIRY_ENABLE_ACTIVATE:
    //         //     proposalEnquiryVM.onActivate(selectedRows);
    //         //     break;
    //         // case E_Custom_Dispatch_Event.PROPOSAL_ENQUIRY_ENABLE_DEACTIVATE:
    //         //     proposalEnquiryVM.onDeactivate(selectedRows);
    //         //     break;
    //     }
    // }

    // useEffect(() => {
    //     <h1>customEventListener</h1>
    //     customEventListener(E_Type_Of_Event.PROPOSAL_ENQUIRY_EVENT, onProposalEnquiryChange);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    if (proposalEnquiryState.isLoading) return <Loader Indicator="Stripe" size="Large" />

    return <div className={`charge-data-box${((searchCounter > 0 && !isShowRightCriteriaPanel) || isShowProposalDetail) ? ' no-filter-preview' : ' filter-preview'}${isShowProposalDetail ? ' show-detail' : ''}`}>
        {/* Show container search panel */}
        <div style={{ display: !isShowProposalDetail ? 'block' : 'none' }}>{memoResizablePanel}</div>
        {/* Show container detail page */}

        {isShowProposalDetail && <ProposalEnquiryDetailContainer />}

        <div className='im-charge-data-search-confirm-modal-container'>
            <SaveNewPreferenceModal visible={proposalEnquiryState.isShowSaveNewConfirmModal} />
            <DeletePreferenceModal visible={proposalEnquiryState.isShowDeleteConfirmModal} />
        </div>
    </div>;

}