import { EMPTY_PROPOSAL_ENQUIRY_SEARCH_CRITERIA } from "domain/entity/ProposalEnquiry/ProposalEnquirySearchCriteria";
import { CriteriaDirectoryCheckboxOption } from "presentation/constant/ProposalEnquiry/CriteriaDirectoryCheckboxOption";
import { ProposalEnquiryConstant } from "presentation/constant/ProposalEnquiry/ProposalEnquiryConstant";
import { ProposalEnquiryCriteriaCheckboxRelatedField as checkboxRelatedFields } from "presentation/constant/ProposalEnquiry/ProposalEnquiryCriteriaCheckboxRelatedField";
import { useProposalEnquiryVM } from "presentation/hook/ProposalEnquiry/useProposalEnquiryVM";
import { useProposalEnquiryTracked } from "presentation/store/ProposalEnquiry/ProposalEnquiryProvider";
import { isNonDefaultValue } from "presentation/utils/searchCriteriaUtil";
import { convertDateToDateForComponent } from "presentation/utils/timeUtil";
import { useCallback, useMemo } from "react";
import { Tags } from "veronica-ui-component/dist/component/core";

export const ProposalEnquiryCriteriaPreviewPanel = () => {
    const [proposalEnquiryState] = useProposalEnquiryTracked();
    const searchCriteria = proposalEnquiryState.searchCriteria;
    const proposalEnquiryVM = useProposalEnquiryVM();
    const isAllDefaultValue = useMemo(() => {
        const attributeField = CriteriaDirectoryCheckboxOption.map(item => item.key)
        const isAllDefault = !attributeField.find((field) => {
            return isNonDefaultValue(searchCriteria, EMPTY_PROPOSAL_ENQUIRY_SEARCH_CRITERIA, checkboxRelatedFields, field)
        })
        return isAllDefault
    }, [searchCriteria])

    const handleRemoveSearchCriteira = useCallback((fieldName: string, isSearch?: boolean) => {
        proposalEnquiryVM.onShowLoading();
        proposalEnquiryVM.onRemoveSearchCriteria(fieldName, isSearch);
        proposalEnquiryVM.onHideLoading();
    }, [proposalEnquiryVM]);
    const proposalEnqConstant = ProposalEnquiryConstant;

    const effectiveFromStr = convertDateToDateForComponent(searchCriteria.effectiveFromDate);
    const effectiveToStr = convertDateToDateForComponent(searchCriteria.effectiveToDate);

    return (
        (!isAllDefaultValue) ? <div className='im-charge-data-search-criteria-group'>
            <div className='im-charge-data-search-criteria-preview-tag-group'>
                {isNonDefaultValue(searchCriteria, EMPTY_PROPOSAL_ENQUIRY_SEARCH_CRITERIA, checkboxRelatedFields, 'companyCode') && <Tags
                    label={`${proposalEnqConstant.Header.COMPANY_CODE} : ${searchCriteria.companyCode?.join(", ")}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('companyCode')}
                />}
                {isNonDefaultValue(searchCriteria, EMPTY_PROPOSAL_ENQUIRY_SEARCH_CRITERIA, checkboxRelatedFields, 'proposalNo') && <Tags
                    label={`${proposalEnqConstant.Header.PROPOSAL_NO}  : ${searchCriteria.proposalNo}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('proposalNo')}
                />}
                {isNonDefaultValue(searchCriteria, EMPTY_PROPOSAL_ENQUIRY_SEARCH_CRITERIA, checkboxRelatedFields, 'operatingTml') && <Tags
                    label={`${proposalEnqConstant.Header.OPERATING_TML} : ${searchCriteria.operatingTml}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('operatingTml')}
                />}
                {isNonDefaultValue(searchCriteria, EMPTY_PROPOSAL_ENQUIRY_SEARCH_CRITERIA, checkboxRelatedFields, 'effectiveDate') && <Tags
                    label={`${proposalEnqConstant.Header.EFFECTIVE_DATE_RANGE} : ${effectiveFromStr} - ${effectiveToStr}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('effectiveDate')}
                />}
                {isNonDefaultValue(searchCriteria, EMPTY_PROPOSAL_ENQUIRY_SEARCH_CRITERIA, checkboxRelatedFields, 'tariffType') && <Tags
                    label={`${proposalEnqConstant.Header.TAR_TYPE}  : ${searchCriteria.tariffType?.join(", ")}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('tariffType')}
                />}
                {isNonDefaultValue(searchCriteria, EMPTY_PROPOSAL_ENQUIRY_SEARCH_CRITERIA, checkboxRelatedFields, 'tariffCode') && <Tags
                    label={`${proposalEnqConstant.Header.TAR_CODE}  : ${searchCriteria.tariffCode?.join(", ")}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('tariffCode')}
                />}
                {isNonDefaultValue(searchCriteria, EMPTY_PROPOSAL_ENQUIRY_SEARCH_CRITERIA, checkboxRelatedFields, 'proposalStatus') && <Tags
                    label={`${proposalEnqConstant.Header.STATUS}  : ${searchCriteria.proposalStatus?.join(", ")}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('proposalStatus')}
                />}
                {isNonDefaultValue(searchCriteria, EMPTY_PROPOSAL_ENQUIRY_SEARCH_CRITERIA, checkboxRelatedFields, 'activeInd') && <Tags
                    label={`${proposalEnqConstant.Header.ACTIVE_IND}  : ${searchCriteria.activeInd}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('activeInd')}
                />}
                {isNonDefaultValue(searchCriteria, EMPTY_PROPOSAL_ENQUIRY_SEARCH_CRITERIA, checkboxRelatedFields, 'confirmStatusList') && <Tags
                    label={`${proposalEnqConstant.Header.CONFIRM_STATUS}  : ${searchCriteria.confirmStatusList?.join(", ")}`}
                    width='fit-content'
                    rounded={true}
                    remove={true}
                    onRemove={() => handleRemoveSearchCriteira('confirmStatusList')}
                />}
            </div>
        </div> : null
    );
};
