import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import { ProposalEnquiryHeader } from "presentation/view/section/ProposalEnquiry/ProposalEnquiryHeader";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

export const ProposalEnquiryHeaderContainer = () => {
    return <ANAInfoWrapper permission={Permission.LOGIN}>
        <MessageBarWrapper>
            {/* <ProposalEnquiryProvider> */}
            <GridStyles />
            <ProposalEnquiryHeader />
            {/* </ProposalEnquiryProvider> */}
        </MessageBarWrapper>
    </ANAInfoWrapper>;
};