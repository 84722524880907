import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import { ProposalEnquiryComp } from "presentation/view/section/ProposalEnquiry/ProposalEnquiryComp";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

export const ProposalEnquiryContainer = () => <ANAInfoWrapper permission={Permission.LOGIN}>
    <MessageBarWrapper>
        {/* <ProposalEnquiryProvider> */}
        <GridStyles />
        <ProposalEnquiryComp />
        {/* </ProposalEnquiryProvider> */}
    </MessageBarWrapper>
</ANAInfoWrapper>;